/*
 * @Author: 天勇 343975805@qq.com
 * @Date: 2022-11-30 17:37:08
 * @LastEditors: 天勇 343975805@qq.com
 * @LastEditTime: 2022-12-01 17:08:30
 * @FilePath: \website\src\store\rem.js
 * @Description: 
 */
! function(n) {
    var e = n.document,
        t = e.documentElement,
        i = 720,
        d = i / 16,
        o = "orientationchange" in n ? "orientationchange" : "resize",
        a = function() {
            var n = t.clientWidth || 320;
            n > 720 && (n = 720);
          
            t.style.fontSize = n / d + "px"
          console.log(t.style.fontSize)
        };
    e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener("DOMContentLoaded", a, !1))

}(window);